<template>
  <div>
    <v-card-text class="py-8">
      <h2>Privacy Policy di www.sa-first.com</h2>
      Per avere informazioni circa i tuoi dati personali raccolti, le finalità
      ed i soggetti con cui i dati vengono condivisi, contatta il Titolare.
      <br />
      <br />
      <h4><strong>Titolare del Trattamento dei Dati </strong></h4>
      AVMSquare DI MENDOLIA ALESSANDRO VITTORIO <br />
      Indirizzo email del titolare: a.mendolia@avmsquare.com
      <br />
      <br />
      <h4><strong>Tipologie di Dati raccolti</strong></h4>
      Il Titolare non fornisce una lista di tipologie di Dati Personali
      raccolti. Dettagli completi su ciascuna tipologia di dati raccolti sono
      forniti nelle sezioni dedicate di questa privacy policy o mediante
      specifici testi informativi visualizzati prima della raccolta dei dati
      stessi. I Dati Personali possono essere liberamente forniti dall'Utente o,
      nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di
      questa Applicazione. Se non diversamente specificato, tutti i Dati
      richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di
      comunicarli, potrebbe essere impossibile per questa Applicazione fornire
      il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come
      facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati,
      senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o
      sulla sua operatività. Gli Utenti che dovessero avere dubbi su quali Dati
      siano obbligatori, sono incoraggiati a contattare il Titolare. L’eventuale
      utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di
      questa Applicazione o dei titolari dei servizi terzi utilizzati da questa
      Applicazione, ove non diversamente precisato, ha la finalità di fornire il
      Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte
      nel presente documento e nella Cookie Policy, se disponibile. L'Utente si
      assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati
      o condivisi mediante questa Applicazione e garantisce di avere il diritto
      di comunicarli o diffonderli, liberando il Titolare da qualsiasi
      responsabilità verso terzi.
      <br />
      <br />
      <h4>
        <strong>Modalità e luogo del trattamento dei Dati raccolti </strong>
      </h4>
      <h4>Modalità di trattamento</h4>
      Il Titolare adotta le opportune misure di sicurezza volte ad impedire
      l’accesso, la divulgazione, la modifica o la distruzione non autorizzate
      dei Dati Personali. Il trattamento viene effettuato mediante strumenti
      informatici e/o telematici, con modalità organizzative e con logiche
      strettamente correlate alle finalità indicate. Oltre al Titolare, in
      alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
      nell’organizzazione di questa Applicazione (personale amministrativo,
      commerciale, marketing, legali, amministratori di sistema) ovvero soggetti
      esterni (come fornitori di servizi tecnici terzi, corrieri postali,
      hosting provider, società informatiche, agenzie di comunicazione) nominati
      anche, se necessario, Responsabili del Trattamento da parte del Titolare.
      L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al
      Titolare del Trattamento.
      <br />
      <br />
      <h4><strong> Base giuridica del trattamento </strong></h4>

      <h4>
        Il Titolare tratta Dati Personali relativi all’Utente in caso sussista
        una delle seguenti condizioni:
      </h4>
      <ul>
        <li>
          l’Utente ha prestato il consenso per una o più finalità specifiche;
          Nota: in alcuni ordinamenti il Titolare può essere autorizzato a
          trattare Dati Personali senza che debba sussistere il consenso
          dell’Utente o un’altra delle basi giuridiche specificate di seguito,
          fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento.
          Ciò non è tuttavia applicabile qualora il trattamento di Dati
          Personali sia regolato dalla legislazione europea in materia di
          protezione dei Dati Personali;
        </li>
        <li>
          il trattamento è necessario all'esecuzione di un contratto con
          l’Utente e/o all'esecuzione di misure precontrattuali;
        </li>
        <li>
          il trattamento è necessario per adempiere un obbligo legale al quale è
          soggetto il Titolare;
        </li>
        <li>
          il trattamento è necessario per l'esecuzione di un compito di
          interesse pubblico o per l'esercizio di pubblici poteri di cui è
          investito il Titolare;
        </li>
        <li>
          il trattamento è necessario per il perseguimento del legittimo
          interesse del Titolare o di terzi.
        </li>
      </ul>
      È comunque sempre possibile richiedere al Titolare di chiarire la concreta
      base giuridica di ciascun trattamento ed in particolare di specificare se
      il trattamento sia basato sulla legge, previsto da un contratto o
      necessario per concludere un contratto.
      <br />
      <br />
      <h4>Luogo</h4>
      I Dati sono trattati presso le sedi operative del Titolare ed in ogni
      altro luogo in cui le parti coinvolte nel trattamento siano localizzate.
      Per ulteriori informazioni, contatta il Titolare.
      <br />
      I Dati Personali dell’Utente potrebbero essere trasferiti in un paese
      diverso da quello in cui l’Utente si trova. Per ottenere ulteriori
      informazioni sul luogo del trattamento l’Utente può fare riferimento alla
      sezione relativa ai dettagli sul trattamento dei Dati Personali.
      <br />
      L’Utente ha diritto a ottenere informazioni in merito alla base giuridica
      del trasferimento di Dati al di fuori dell’Unione Europea o ad
      un’organizzazione internazionale di diritto internazionale pubblico o
      costituita da due o più paesi, come ad esempio l’ONU, nonché in merito
      alle misure di sicurezza adottate dal Titolare per proteggere i Dati.
      <br />
      L’Utente può verificare se abbia luogo uno dei trasferimenti appena
      descritti esaminando la sezione di questo documento relativa ai dettagli
      sul trattamento di Dati Personali o chiedere informazioni al Titolare
      contattandolo agli estremi riportati in apertura.

      <br />
      <br />
      <h4><strong>Periodo di conservazione </strong></h4>
      I Dati sono trattati e conservati per il tempo richiesto dalle finalità
      per le quali sono stati raccolti.

      <br />
      Pertanto:

      <ul>
        <li>
          I Dati Personali raccolti per scopi collegati all’esecuzione di un
          contratto tra il Titolare e l’Utente saranno trattenuti sino a quando
          sia completata l’esecuzione di tale contratto.
        </li>
        <li>
          I Dati Personali raccolti per finalità riconducibili all’interesse
          legittimo del Titolare saranno trattenuti sino al soddisfacimento di
          tale interesse. L’Utente può ottenere ulteriori informazioni in merito
          all’interesse legittimo perseguito dal Titolare nelle relative sezioni
          di questo documento o contattando il Titolare.
        </li>
      </ul>

      Quando il trattamento è basato sul consenso dell’Utente, il Titolare può
      conservare i Dati Personali più a lungo sino a quando detto consenso non
      venga revocato. Inoltre, il Titolare potrebbe essere obbligato a
      conservare i Dati Personali per un periodo più lungo in ottemperanza ad un
      obbligo di legge o per ordine di un’autorità.
      <br />

      Al termine del periodo di conservazione i Dati Personali saranno
      cancellati. Pertanto, allo spirare di tale termine il diritto di accesso,
      cancellazione, rettificazione ed il diritto alla portabilità dei Dati non
      potranno più essere esercitati.
      <br />
      <br />

      <h4>
        <strong>Diritti dell’Utente </strong>
      </h4>

      Gli Utenti possono esercitare determinati diritti con riferimento ai Dati
      trattati dal Titolare.
      <br />
      In particolare, l’Utente ha il diritto di:

      <ul>
        <li>
          revocare il consenso in ogni momento. L’Utente può revocare il
          consenso al trattamento dei propri Dati Personali precedentemente
          espresso.
        </li>
        <li>
          opporsi al trattamento dei propri Dati. L’Utente può opporsi al
          trattamento dei propri Dati quando esso avviene su una base giuridica
          diversa dal consenso. Ulteriori dettagli sul diritto di opposizione
          sono indicati nella sezione sottostante.
        </li>
        <li>
          accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni
          sui Dati trattati dal Titolare, su determinati aspetti del trattamento
          ed a ricevere una copia dei Dati trattati.
        </li>
        <li>
          verificare e chiedere la rettificazione. L’Utente può verificare la
          correttezza dei propri Dati e richiederne l’aggiornamento o la
          correzione.
        </li>
        <li>
          ottenere la limitazione del trattamento. Quando ricorrono determinate
          condizioni, l’Utente può richiedere la limitazione del trattamento dei
          propri Dati. In tal caso il Titolare non tratterà i Dati per alcun
          altro scopo se non la loro conservazione.
        </li>
        <li>
          ottenere la cancellazione o rimozione dei propri Dati Personali.
          Quando ricorrono determinate condizioni, l’Utente può richiedere la
          cancellazione dei propri Dati da parte del Titolare.
        </li>
        <li>
          ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente
          ha diritto di ricevere i propri Dati in formato strutturato, di uso
          comune e leggibile da dispositivo automatico e, ove tecnicamente
          fattibile, di ottenerne il trasferimento senza ostacoli ad un altro
          titolare. Questa disposizione è applicabile quando i Dati sono
          trattati con strumenti automatizzati ed il trattamento è basato sul
          consenso dell’Utente, su un contratto di cui l’Utente è parte o su
          misure contrattuali ad esso connesse.
        </li>
        <li>
          proporre reclamo. L’Utente può proporre un reclamo all’autorità di
          controllo della protezione dei dati personali competente o agire in
          sede giudiziale.
        </li>
      </ul>

      <br />

      <h4><strong>Dettagli sul diritto di opposizione </strong></h4>
      Quando i Dati Personali sono trattati nell’interesse pubblico,
      nell’esercizio di pubblici poteri di cui è investito il Titolare oppure
      per perseguire un interesse legittimo del Titolare, gli Utenti hanno
      diritto ad opporsi al trattamento per motivi connessi alla loro situazione
      particolare.
      <br />
      Si fa presente agli Utenti che, ove i loro Dati fossero trattati con
      finalità di marketing diretto, possono opporsi al trattamento senza
      fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con
      finalità di marketing diretto gli Utenti possono fare riferimento alle
      rispettive sezioni di questo documento.
      <br />
      <br />

      <h4><strong>Come esercitare i diritti </strong></h4>
      Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una
      richiesta agli estremi di contatto del Titolare indicati in questo
      documento. Le richieste sono depositate a titolo gratuito e evase dal
      Titolare nel più breve tempo possibile, in ogni caso entro un mese.
      <br />
      <br />
      <h4>
        <strong>Ulteriori informazioni sul trattamento </strong>
      </h4>
      <strong>Difesa in giudizio </strong>
      I Dati Personali dell’Utente possono essere utilizzati da parte del
      Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
      instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione
      o dei Servizi connessi da parte dell’Utente. L’Utente dichiara di essere
      consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati
      per ordine delle autorità pubbliche.
      <br />

      <strong>Informative specifiche </strong>
      Su richiesta dell’Utente, in aggiunta alle informazioni contenute in
      questa privacy policy, questa Applicazione potrebbe fornire all'Utente
      delle informative aggiuntive e contestuali riguardanti Servizi specifici,
      o la raccolta ed il trattamento di Dati Personali.
      <br />

      <strong>Log di sistema e manutenzione </strong>
      Per necessità legate al funzionamento ed alla manutenzione, questa
      Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero
      raccogliere log di sistema, ossia file che registrano le interazioni e che
      possono contenere anche Dati Personali, quali l’indirizzo IP Utente.
      <br />

      <strong>Informazioni non contenute in questa policy </strong>
      Ulteriori informazioni in relazione al trattamento dei Dati Personali
      potranno essere richieste in qualsiasi momento al Titolare del Trattamento
      utilizzando gli estremi di contatto.
      <br />

      <strong>Risposta alle richieste “Do Not Track” </strong>

      Questa Applicazione non supporta le richieste “Do Not Track”. Per scoprire
      se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente
      è invitato a consultare le rispettive privacy policy.
      <br />
      <strong>Modifiche a questa privacy policy </strong>

      Il Titolare del Trattamento si riserva il diritto di apportare modifiche
      alla presente privacy policy in qualunque momento notificandolo agli
      Utenti su questa pagina e, se possibile, su questa Applicazione nonché,
      qualora tecnicamente e legalmente fattibile, inviando una notifica agli
      Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si
      prega dunque di consultare con frequenza questa pagina, facendo
      riferimento alla data di ultima modifica indicata in fondo. Qualora le
      modifiche interessino trattamenti la cui base giuridica è il consenso, il
      Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se
      necessario.
    </v-card-text>
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicyText",
  props: {},
  components: {},
  // data: () => ({
  //   privacy_policy_dialog: false,
  // }),
  methods: {},
  // mounted() {
  //   EventBus.$on("open_privacy_policy", () => {
  //     console.log("open_cookie");
  //     this.privacy_policy_dialog = true;
  //   });
  // },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>